import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  TextField,
  Grid,
  Card,
  Select,
  CardContent,
  MenuItem,
  Slider,
  FormHelperText,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import H1 from "./assets/h1.png";
import H2 from "./assets/h2.png";
import H3 from "./assets/h3.png";
import H4 from "./assets/h4.png";
import H5 from "./assets/h5.png";
import H6 from "./assets/h6.png";
import H7 from "./assets/h7.png";
import H8 from "./assets/h8.png";
import H9 from "./assets/h9.png";
import H10 from "./assets/h10.png";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../../shared/inputForm/inputField";
import { toast } from "sonner";
import {
  addMoraledailyQuestion,
  getAllMoraleDailyQuestion,
} from "../../../../../actions/moraleDaily";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getAnswerScale } from "../../../../../actions/questionTopic";

const StyledButton = styled(Button)({
  margin: 4,
  color: "#000000",
  backgroundColor: "#ffffff",
  ":hover": {
    backgroundColor: "#bdbdbd",
  },
});

const StyledCoinChoice = styled(Avatar)({
  width: 24,
  height: 24,
  fontSize: 14,
  backgroundColor: "#f5cb00",
  color: "#dc8100",
  marginLeft: 10,
  position: "absolute",
  right: 10,
});

const marks = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
];

const imgInsight = (id) => {
  const idModTen = id % 10;
  switch (idModTen) {
    case 0:
      return H1;
    case 1:
      return H2;
    case 2:
      return H3;
    case 3:
      return H4;
    case 4:
      return H5;
    case 5:
      return H6;
    case 6:
      return H7;
    case 7:
      return H8;
    case 8:
      return H9;
    case 9:
      return H10;
    default:
      break;
  }
};

const bgCard = [
  "#ff1744",
  "#d500f9",
  "#651fff",
  "#2979ff",
  "#14a37f",
  "#ffc400",
  "#ff9100",
  "#357a38",
  "#f73378",
  "#6D4C41",
];

const getBgCard = (id) => {
  if (id === 0) {
    return "#BFBFBF";
  }
  const idModTen = id % 10;
  return bgCard[idModTen];
};

const validationSchema = yup.object().shape({
  questionType: yup.string().required("Required"),
  idQuestionTopic: yup.string().required("Required"),
  question: yup.string().max(250).required("Please enter question"),
  reward: yup.number().min(1).integer().required(),
});

const defaultValues = {
  questionType: "",
  idQuestionTopic: "",
  question: "",
  reward: 1,
};

const answer6Scale = ["Very Happy", "Happy", "Little Happy", "Little Bad", "Bad", "Very Bad"];
const answer5Scale = ["Very Happy", "Happy", "Neutral", "Bad", "Very Bad"];

const DialogCreateQuestion = ({ open, handleClose, selectCompany }) => {
  const dispatch = useDispatch();
  const { result: questionTopic } = useSelector((state) => state.questionTopic);
  const { result: answerScale } = useSelector((state) => state.answerScale);

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (selectCompany) {
      dispatch(getAnswerScale(selectCompany.idCompany))
    }
  }, [selectCompany])


  const onSubmit = async (values) => {
    if (!idQuestionTopic) {
      toast.error("โปรดเลือกหัวข้อคำถาม");
    } else if (question.trim().length === 0) {
      toast.error("โปรดเพิ่มคำถาม");
    } else {
      const res = await dispatch(addMoraledailyQuestion(values));
      if (res && res.status === 201) {
        toast.success("สำเร็จ");
        dispatch(getAllMoraleDailyQuestion());
        handleClose();
      } else {
        toast.error("เกิดข้อผิดพลาด");
      }
    }
  };

  const idQuestionTopic = watch("idQuestionTopic");
  const question = watch("question");
  const reward = watch("reward");

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"สร้างคำถามใหม่"}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Accordion expanded={true}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>1. ประเภทคำตอบ</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Controller
                    control={control}
                    name="questionType"
                    render={({ field, fieldState }) => (
                      <FormControl
                        component="fieldset"
                        error={Boolean(fieldState.error)}
                      >
                        <RadioGroup row {...field}>
                          <FormControlLabel
                            value="choice"
                            control={<Radio />}
                            label="ตัวเลือก"
                          />
                          <FormControlLabel
                            value="text"
                            control={<Radio />}
                            label="ข้อความ"
                          />
                        </RadioGroup>
                        <FormHelperText error>
                          {fieldState?.error?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={true}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>2. หัวข้อคำถาม</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Controller
                    name="idQuestionTopic"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <Select {...field} error={Boolean(fieldState.error)}>
                          {questionTopic?.map((topic) => (
                            <MenuItem
                              value={topic.idQuestionTopic}
                              key={topic.idQuestionTopic}
                            >
                              {topic.questionTopic}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error>
                          {fieldState?.error?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={true}>
                <AccordionSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>3. คำถาม</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <InputField control={control} name={"question"} />
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={true}>
                <AccordionSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>4. จำนวนเหรียญรางวัล</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Controller
                    control={control}
                    name={"reward"}
                    render={({ field }) => (
                      <Slider
                        {...field}
                        marks={marks}
                        defaultValue={1}
                        valueLabelDisplay="auto"
                        min={1}
                        max={10}
                      />
                    )}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                variant="outlined"
                style={{
                  height: "100%",
                  backgroundColor: getBgCard(idQuestionTopic),
                }}
              >
                <CardContent style={{ paddingBottom: 0, textAlign: "center" }}>
                  <img
                    style={{
                      marginBottom: 10,
                      padding: 8,
                      backgroundColor: "#FFFFFF",
                      borderRadius: 8,
                    }}
                    src={imgInsight(idQuestionTopic)}
                    alt=""
                    width={80}
                  />
                  {watch("question") === "" ? (
                    <Typography
                      variant="h5"
                      gutterBottom
                      style={{ textAlign: "center", color: "#BFBFBF" }}
                    >
                      {"... แสดงตัวอย่างคำถาม ...."}
                    </Typography>
                  ) : (
                    <Typography
                      variant="h5"
                      gutterBottom
                      style={{ textAlign: "center", color: "#FFFFFF" }}
                    >
                      {question}
                    </Typography>
                  )}
                  {answerScale && watch("questionType") === "choice" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                        margin: "auto",
                      }}
                    >
                      {answerScale.answerScale === 6
                        ? answer6Scale.map((item, index) => (
                            <StyledButton key={index} variant={"outlined"}>
                              <div style={{ display: "flex" }}>
                                <Typography variant="button">{item}</Typography>
                                <StyledCoinChoice>{reward}</StyledCoinChoice>
                              </div>
                            </StyledButton>
                          ))
                        : answer5Scale.map((item, index) => (
                            <StyledButton key={index} variant={"outlined"}>
                              <div style={{ display: "flex" }}>
                                <Typography variant="button">{item}</Typography>
                                <StyledCoinChoice>{reward}</StyledCoinChoice>
                              </div>
                            </StyledButton>
                          ))}
                    </div>
                  ) : (
                    <div>
                      <TextField
                        style={{ width: "100%", backgroundColor: "#FFF" }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        multiline
                        rows={4}
                      />
                      <StyledButton variant={"contained"}>
                        <div style={{ display: "flex", width: 100 }}>
                          <Typography variant="button">ส่งคำตอบ</Typography>
                          <StyledCoinChoice>{reward}</StyledCoinChoice>
                        </div>
                      </StyledButton>
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            บันทึก
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCreateQuestion;
