import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Typography,
  Grid,
  Card,
  CardActions,
  Divider,
  Chip,
  Stack,
  Box,
} from "@mui/material";
import Button from "@mui/material/Button";
import H1 from "./assets/h1.png";
import H2 from "./assets/h2.png";
import H3 from "./assets/h3.png";
import H4 from "./assets/h4.png";
import H5 from "./assets/h5.png";
import H6 from "./assets/h6.png";
import H7 from "./assets/h7.png";
import H8 from "./assets/h8.png";
import H9 from "./assets/h9.png";
import H10 from "./assets/h10.png";

import { useDispatch, useSelector } from "react-redux";
import { getAllMoraleDailyQuestion } from "../../../../../actions/moraleDaily";
import { getDepartment } from "../../../../../actions/department";
import DialogPublish from "./dialogPublish";
import LoadingIcon from "../../../shared/general/loadingIcon";

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const StyledCard = styled(Card)({
  height: "100%",
  borderRadius: 8,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const WrapCardHeaderInsight = styled("div")({
  display: "flex",
  padding: 5,
  width: "70%",
  borderTopRightRadius: 50,
  borderBottomRightRadius: 50,
  justifyContent: "space-between",
  alignItems: "center",
});

const WrapCardHeaderInsight2 = styled("div")({
  display: "flex",
  padding: 5,
  alignItems: "center",
  backgroundColor: "#EFEFEF",
  ["@media only screen and (min-width:600px)"]: {
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    marginLeft: "auto",
  },
  ["@media only screen and (max-width:599px)"]: {
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    marginRight: "auto",
  },
});

const WrapCardHeaderInsightImage = styled("div")({
  backgroundColor: "white",
  borderRadius: "50%",
  width: 35,
  height: 35,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledCardHeaderInsightIcon = styled(Avatar)({
  width: 35,
  height: 35,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFD923",
  color: "#EA8900",
});

const bgCard = [
  "#ff1744",
  "#d500f9",
  "#651fff",
  "#2979ff",
  "#14a37f",
  "#ffc400",
  "#ff9100",
  "#357a38",
  "#f73378",
  "#6D4C41",
];

const getBgCard = (id) => {
  if (id === 0) {
    return "#BFBFBF";
  }
  const idModTen = id % 10;
  return bgCard[idModTen];
};

const imgInsight = (id) => {
  const idModTen = id % 10;
  switch (idModTen) {
    case 0:
      return H1;
    case 1:
      return H2;
    case 2:
      return H3;
    case 3:
      return H4;
    case 4:
      return H5;
    case 5:
      return H6;
    case 6:
      return H7;
    case 7:
      return H8;
    case 8:
      return H9;
    case 9:
      return H10;
    default:
      break;
  }
};

const MoraleDailyAll = () => {
  const [selectQuestion, setSelectQuestion] = React.useState(null);
  const [openPublic, setOpenPublic] = React.useState(false);
  const [selectTopic, setSelectTopic] = React.useState(null);

  const handleOpenPublic = (question) => {
    setSelectQuestion(question);
    setOpenPublic(true);
  };

  const handleClosePublic = () => {
    setOpenPublic(false);
  };

  const handleChangeTopic = (topic) => {
    setSelectTopic(topic);
  };

  const dispatch = useDispatch();
  const { result: moraleDailyQuestion, isFetching } = useSelector(
    (state) => state.moraleDailyQuestion
  );
  const { result: questionTopic } = useSelector((state) => state.questionTopic);

  useEffect(() => {
    dispatch(getAllMoraleDailyQuestion());
  }, []);

  return (
    <>
      {questionTopic && (
        <div style={{ marginBottom: "24px" }}>
          <Typography gutterBottom variant="h6">
            หัวข้อคำถาม
          </Typography>
          <StyledChip
            clickable
            label={"ALL"}
            color={selectTopic === null ? "primary" : "default"}
            onClick={() => handleChangeTopic(null)}
          />
          {questionTopic.map((data) => (
            <StyledChip
              key={data.idQuestionTopic}
              clickable
              label={data.questionTopic}
              color={selectTopic === data.idQuestionTopic ? "primary" : "default"}
              onClick={() => handleChangeTopic(data.idQuestionTopic)}
            />
          ))}
        </div>
      )}
       {isFetching ? (
        <LoadingIcon />
      ) : (
        <Grid container spacing={2}>
          {moraleDailyQuestion &&
            moraleDailyQuestion
              .filter((data) =>
                selectTopic ? selectTopic === data.idQuestionTopic : true
              )
              .map((data) => {
                return (
                  <Grid item xs={12} md={6} lg={4} key={data.idMoraledailyQuestion}>
                    <StyledCard elevation={3}>
                      <div>
                        <Stack direction={{ xs: "column", sm: "row" }}>
                          <WrapCardHeaderInsight
                            style={{
                              backgroundColor: getBgCard(data.idQuestionTopic),
                            }}
                          >
                            <Typography noWrap style={{ color: "#FFF" }}>
                              {data.questionTopic}
                            </Typography>
                            <WrapCardHeaderInsightImage>
                              <img
                                alt="questionImg"
                                width={24}
                                height={24}
                                src={imgInsight(data.idQuestionTopic)}
                              />
                            </WrapCardHeaderInsightImage>
                          </WrapCardHeaderInsight>
                          <WrapCardHeaderInsight2>
                            <StyledCardHeaderInsightIcon>
                              {data.reward}
                            </StyledCardHeaderInsightIcon>
                            <Typography style={{ paddingLeft: 5 }}>
                              COIN
                            </Typography>
                          </WrapCardHeaderInsight2>
                        </Stack>
                        <div style={{ padding: 20 }}>
                          <Typography variant="h5" textAlign={"center"}>
                            {data.question}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            textAlign={"center"}
                          >
                            {`ประเภทคำตอบ ${data.questionType === "text" ? "ข้อความ" : "ตัวเลือก"}`}
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <Divider />
                        <CardActions style={{ justifyContent: "center" }}>
                          <Button onClick={() => handleOpenPublic(data)}>
                            ส่งคำถาม
                          </Button>
                        </CardActions>
                      </div>
                    </StyledCard>
                  </Grid>
                );
              })}
        </Grid>
      )}
      {openPublic && (
        <DialogPublish
          open={openPublic}
          handleClose={handleClosePublic}
          selectQuestion={selectQuestion}
        />
      )}
    </>
  );
};

export default MoraleDailyAll;
