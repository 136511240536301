import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import Login from "./components/pages/LoginPage";
import Landing from "./components/pages/landing";
import ContactUs from "./components/pages/contactus";
import { UnauthorizedPage, NotFoundPage } from "./components/pages/error";

//User
import Home from "./components/pages/user/home";
import ProfilePage from "./components/pages/user/profile";
import ViewProfile from "./components/pages/user/profile/ViewProfile";
import Notification from "./components/pages/NotificationPage";
import SendHeart from "./components/pages/user/heartTransfer";
import Feedback from "./components/pages/user/feedback";
import LeaderBoard from "./components/pages/user/leaderBoard";
import MoraleQuestion from "./components/pages/user/moraleQuestion";
import RedeemReward from "./components/pages/user/redeemReward";
import MyReward from "./components/pages/user/myReward";
import MyActivity from "./components/pages/user/myActivity";
import Activity from "./components/pages/user/activity";
import Wallet from "./components/pages/user/wallet";
import Assessment360User from "./components/pages/user/assessment360";
import Assessment360Question from "./components/pages/user/assessment360/assessment360Question";
import DashboardCoach from "./components/pages/user/coach/Dashboard";
import BookingSummary from "./components/pages/user/coach/BookingSummary";
import Coachee from "./components/pages/user/coach/Coachee";
import FormRegisterCoach from "./components/pages/user/coach/FormRegisterCoach";
import SearchCoach from "./components/pages/user/coachee/SearchCoach";
import BookingCoach from "./components/pages/user/coachee/Booking";
import Booking from "./components/pages/user/coachee/Booking";
import MeetingForm from "./components/pages/user/meet";
import HistoryCoachee from "./components/pages/user/coachee/History";
import Favorite from "./components/pages/user/coachee/Favorite";
import SaveCoaching from "./components/pages/user/coachee/SaveCoaching";
import HistoryCoach from "./components/pages/user/coach/History";
import MyCoach from "./components/pages/user/coachee/Home";
import EnterAnnouncement from "./components/pages/user/announcement";
import Board from "./components/pages/user/communication";
import CreateBoard from "./components/pages/user/communication/CreateBoard";
import Mymission from "./components/pages/user/myMission";
import MyPoll from "./components/pages/user/myPoll";
import MyMoraleDaily from "./components/pages/user/myMoraleDaily";
import MoraleQuestionAnswer from "./components/pages/user/moraleQuestion/moraleQuestionAnswer";
import BookingList from "./components/pages/user/coachee/BookingList";
import ApproveBooking from "./components/pages/user/coachee/ApproveBooking";
import RewardDetail from "./components/pages/user/redeemReward/rewardDetail";

//Manager
import MoraleKpi from "./components/pages/shared/moraleKpi";
import {
  DashboardManagerPage,
  MissionAssign,
  Analytic,
} from "./components/pages/manager";

// Admin
import {
  CostReward,
  SpecialCoin,
  Transfer,
  SettingPage,
  EmployeesPage,
  RedeemTransaction,
  RewardManage,
  ActivityManage,
  ActivityRegister,
  CreateActivity,
  CreateActivityAlt,
  EditActivity,
  NotificationManage,
  MoraleQuestionManage,
  MoraleDescriptionsManage,
  CreateAssessment360,
  EditAssessment360,
  Assessment360,
  Assessment360Descriptions,
  AnswerAssessment360,
  UserAnswerAssessment360,
  Announcement,
  CreateAnnouncement,
  BoardAdmin,
  PollManage,
  Report,
  AssignAssessment360,
  MoraleDaily,
  MoraleDailyAnswer,
  CompanySendCoin,
  UniReward,
  UniRewardDetail,
  UniRewardOrder,
  UniRewardCart,
} from "./components/pages/admin";

import UniTeamReward from "./components/pages/uniTeam/reward";
import CreateUniReward from "./components/pages/uniTeam/reward/create/createUniReward";
import EditUniReward from "./components/pages/uniTeam/reward/edit/editUniReward";
import ManageCoin from "./components/pages/admin/manage/coin";
import CoinQuota from "./components/pages/admin/manage/coin-quota";
import MoraleDailyDashboard from "./components/pages/admin/moraleDashboard/DailyDashboard";
import BudgetCoin from "./components/pages/admin/dashboard/budgetCoin";
import Transaction from "./components/pages/user/transaction";
import CompanyTransaction from "./components/pages/admin/coinTransactionDashboard";
import UniRewardTransaction from "./components/pages/uniTeam/reward/transaction/transaction";
import UniRewardSaleHistory from "./components/pages/uniTeam/reward/saleHistory";
import DetailUniReward from "./components/pages/uniTeam/reward/detail";
import RewardTransaction from "./components/pages/uniTeam/reward/transaction/transaction";
import CompanyUniRewardTransaction from "./components/pages/admin/manage/reward/uniReward/transaction/transaction";
import CompanyUniRewardDetail from "./components/pages/admin/manage/reward/uniReward/detail/detailReward";
import CompanyRewardDetail from "./components/pages/admin/manage/reward/companyReward/detail/detailReward";
import CreateCompanyReward from "./components/pages/admin/manage/reward/companyReward/create/createReward";
import EditCompanyReward from "./components/pages/admin/manage/reward/companyReward/edit/editReward";
import CompanyRewardTransaction from "./components/pages/admin/manage/reward/companyReward/transaction/transactionCompanyReward";


const Routers = () => {
  const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);

  // const RedirectLogin = () => (
  //   <Route>
  //     <Redirect to="/login" />
  //   </Route>
  // );

  // const RedirectLanding = () => (
  //   <Route>
  //     <Navigate to="/landing" replace />
  //   </Route>
  // );

  // const AdminRoute = ({ component: Component, ...rest }) => {
  //   if (currentUser) {
  //     return (
  //       <Route
  //         {...rest}
  //         render={(props) =>
  //           currentUser.roles.includes("ROLE_ADMIN") === true ? (
  //             <Component {...props} />
  //           ) : (
  //             <Navigate to="/unauthorized" replace />
  //             // <Redirect to="/unauthorized" />
  //           )
  //         }
  //       />
  //     );
  //   } else {
  //     return <RedirectLanding />;
  //   }
  // };

  // const ManagerRoute = ({ component: Component, ...rest }) => {
  //   if (currentUser) {
  //     return (
  //       <Route
  //         {...rest}
  //         render={(props) =>
  //           currentUser.roles.includes("ROLE_MANAGER") === true ? (
  //             <Component {...props} />
  //           ) : (
  //             <Navigate to="/unauthorized" replace />
  //             // <Redirect to="/unauthorized" />
  //           )
  //         }
  //       />
  //     );
  //   } else {
  //     return <RedirectLanding />;
  //   }
  // };

  // const UserRoute = ({ component: Component, ...rest }) => {
  //   if (currentUser) {
  //     return (
  //       <Route
  //         {...rest}
          
  //         render={(props) =>
  //           currentUser.roles.includes("ROLE_USER") === true ||
  //           currentUser.roles.includes("ROLE_MANAGER") === true ||
  //           currentUser.roles.includes("ROLE_ADMIN") === true ? (
  //             <Component {...props} />
  //           ) : (
  //             <Navigate to="/unauthorized" replace />
  //             // <Redirect to="/unauthorized" />
  //           )
  //         }
  //       />
  //       // <Route
  //       //   {...rest}
  //       //   render={(props) =>
  //       //     currentUser.roles.includes("ROLE_USER") === true ? (
  //       //       <Component {...props} />
  //       //     ) : (
  //       //       <Redirect to="/unauthorized" />
  //       //     )
  //       //   }
  //       // />
  //     );
  //   } else {
  //     return <RedirectLanding />;
  //   }
  // };

  // const RequireAuth = ({ allowedRoles }) => {
  //   if (currentUser) {
  //     const splitRole = currentUser.roles.split(",").map((item) => item.trim());
  //     const uniTeam = splitRole.includes('ROLE_UNI_TEAM')
  //     if(uniTeam) {
  //       <Navigate to="/uni-team/manage/reward" replace />
  //     } else {
  //       return splitRole.find((role) => allowedRoles.includes(role)) 
  //         ? <Outlet /> 
  //         : <Navigate to="/unauthorized" />
  //     }
  //   } else {
  //     return <Navigate to="/landing" replace />;
  //   }
  // };

  const RequireAuth = ({ allowedRoles }) => {
    if (currentUser && isLoggedIn) {
      return allowedRoles.includes(currentUser.idRole) ? (
        <Outlet />
      ) : (
        <Navigate to="/unauthorized" />
      );
    } else {
      return <Navigate to="/login" replace />;
    }
  };

  const PublicRoutes = () => {
    return currentUser ? <Navigate to="/home" replace /> : <Outlet />;
  };

  const CoachRoutes = () => {
    return currentUser.idCoach ? <Outlet /> : <Navigate to="/coaching" />
  };

  const HomeRoute = () => {
    const isUniTeam = currentUser.idRole === 9;
    if (isUniTeam) {
      return <Navigate to="/uni-team/reward" />
    } else {
      return <Navigate to="/home" replace />;
    }
  };

  return (
    <Routes>
      {/* public routes */}
      <Route element={<PublicRoutes /> }>
        <Route path="landing" element={<Landing />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="login" element={<Login />} />
      </Route>

      {/* All Role Route */}
      <Route path="/" element={<RequireAuth allowedRoles={[1, 2, 3, 4, 5, 9]} /> }>
        <Route index element={<HomeRoute />} />
        <Route path="unauthorized" element={<UnauthorizedPage />} />
      </Route>

      {/* User Route */} 
      <Route element={<RequireAuth allowedRoles={[1, 2, 3, 4, 5]} /> }>
        <Route path="home" element={<Home />} />
        <Route path="notification" element={<Notification />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="send-heart" element={<SendHeart />} />
        <Route path="feedback" element={<Feedback />} />
        <Route path="leaderboard" element={<LeaderBoard />} />
        <Route path="morale-question" element={<MoraleQuestion />} />
        <Route path="morale-question/:idMorale" element={<MoraleQuestionAnswer />} />
        <Route path="my-rewards" element={<MyReward />} />
        <Route path="reward">
          <Route index element={<RedeemReward />} />
          <Route path="detail" element={<RewardDetail />} />
        </Route>
        <Route path="my-activity" element={<MyActivity />} />
        <Route path="activity" element={<Activity />} />
        <Route path="wallet" element={<Wallet />} />
        <Route path="transaction" element={<Transaction />} />
        <Route path="assessment360/question" element={<Assessment360Question />} />
        <Route path="assessment360" element={<Assessment360User />} />
        <Route path="coaching">
          {/* Coach Route */}
          <Route element={<CoachRoutes />}>
            <Route path="coach-dashboard" element={<DashboardCoach />} />
            <Route path="coach-booking" element={<BookingSummary />} />
            <Route path="coachee-list" element={<Coachee />} />
            <Route path="coach-history" element={<HistoryCoach />} />
          </Route>
          <Route index element={<MyCoach />} />
          <Route path="coachee-booking" element={<BookingList />} />
          <Route path="coachee-booking-approve" element={<ApproveBooking />} />
          <Route path="search-coach" element={<SearchCoach />} />
          <Route path="favorite-coach" element={<Favorite />} />
          <Route path="coachee-history" element={<HistoryCoachee />} />
          <Route path="register-coach" element={<FormRegisterCoach />} />
          <Route path="coachee-Booking/:id" element={<BookingCoach />} />
          <Route path="coach-booking/:id" element={<Booking />} />
          <Route path="save-coaching/:id" element={<SaveCoaching />} />
          <Route path="profile/:id" element={<ViewProfile />} />
        {/* <UserRoute path="/coaching/formMeeting/:id" component={MeetingForm} /> */}
        </Route>
        <Route path="announcement" element={<EnterAnnouncement />} />
        <Route path="communication" element={<Board />} />
        <Route path="communication-create" element={<CreateBoard />} />
        <Route path="createBoard" element={<CreateAnnouncement />} />
        <Route path="my-mission" element={<Mymission />} />
        <Route path="my-poll" element={<MyPoll />} />
        <Route path="my-morale-daily" element={<MyMoraleDaily />} />
      </Route>

      {/* Manager Route */}
      <Route path="manager" element={<RequireAuth allowedRoles={[2, 3, 5]} />}>
        <Route path="dashboard" element={<DashboardManagerPage />} />
        <Route path="dashboard/morale-kpi" element={<MoraleKpi />} />
        <Route path="mission-assign" element={<MissionAssign />} />
        <Route path="analytic" element={<Analytic />} />
        {/* <ManagerRoute path="/manager/dashboard" component={DashboardManagerPage} />
        <ManagerRoute path="/manager/dashboard/morale-kpi" component={MoraleKpi} />
        <ManagerRoute path="/manager/mission-assign" component={MissionAssign} />
        <ManagerRoute path="/manager/analytic" component={Analytic} /> */}
      </Route>

      {/* Admin Route */}
      <Route path="admin" element={<RequireAuth allowedRoles={[4, 5]} />}>
        {/* <Route path="employees" element={<EmployeesPage />} /> */}
        <Route path="setting" element={<SettingPage />} />
        <Route path="redeem-transaction" element={<RedeemTransaction />} />
        <Route path="dashboard/morale-kpi" element={<MoraleKpi />} />
        <Route path="dashboard/morale-daily/kpi" element={<MoraleDailyDashboard />} />
        <Route path="dashboard/transaction" element={<CompanyTransaction />} />
        <Route path="dashboard/budget-coin" element={<BudgetCoin />} />
        <Route path="dashboard/cost-reward" element={<CostReward />} />
        <Route path="dashboard/transfer" element={<Transfer />} />
        <Route path="dashboard/special-coin" element={<SpecialCoin />} />
        <Route path="manage">
          <Route path="reward">
            <Route index element={<RewardManage />} />

            {/* <Route path="create" element={<CreateReward />} />
            <Route path="edit/:id" element={<EditReward />} />
            <Route path="detail" element={<DetailReward />} />
            <Route path=":id/cost" element={<StoreReward />} /> */}
            
            
            <Route path="company-reward/create" element={<CreateCompanyReward />} />
            <Route path="company-reward/edit/:id" element={<EditCompanyReward />} />
            <Route path="company-reward/detail" element={<CompanyRewardDetail />} />
            <Route path="company-reward/cost" element={<CompanyRewardTransaction />} />
            
            <Route path="uni-reward/detail" element={<CompanyUniRewardDetail />} />
            <Route path="uni-reward/cost" element={<CompanyUniRewardTransaction />} />
          </Route>
          <Route path="activity" element={<ActivityManage />} />
          <Route path="activity-register/:id" element={<ActivityRegister />} />
          {/* <Route path="create-activity" element={<CreateActivity />} /> */}
          <Route path="create-activity-alt" element={<CreateActivityAlt />} />
          <Route path="edit-activity" element={<EditActivity />} />
          <Route path="notification" element={<NotificationManage />} />
          <Route path="morale-daily/:id" element={<MoraleDailyAnswer />} />
          <Route path="morale-question/:id" element={<MoraleDescriptionsManage />} />
          <Route path="morale-question" element={<MoraleQuestionManage />} />
          <Route path="poll" element={<PollManage />} />
          <Route path="assessment360" element={<Assessment360 />} />
          <Route path="createassessment360" element={<CreateAssessment360 />} />
          <Route path="assessment360/:id" element={<Assessment360Descriptions />} />
          <Route path="editassessment360/:id" element={<EditAssessment360 />} />
          <Route path="coin" element={<ManageCoin />} />
          <Route path="coin-quota" element={<CoinQuota />} />
        </Route>
        <Route path="assessment360" element={<AssignAssessment360 />} />
        <Route path="assessment360/answer" element={<AnswerAssessment360 />} />
        <Route path="user-answerassessment360" element={<UserAnswerAssessment360 />} />
        <Route path="announcement" element={<Announcement />} />
        <Route path="create-announcement" element={<CreateAnnouncement />} />
        <Route path="communication" element={<BoardAdmin />} />
        <Route path="report" element={<Report />} />
        <Route path="company-send-coin" element={<CompanySendCoin />} />
        <Route path="uni-reward">
          <Route index element={<UniReward />} />
          <Route path="detail/:id" element={<UniRewardDetail />} />
          <Route path="cart" element={<UniRewardCart />} />
          <Route path="order" element={<UniRewardOrder />} />
        </Route>
        {/* <AdminRoute path="/admin/employees" component={EmployeesPage} />
        <AdminRoute path="/admin/setting" component={SettingPage} />
        <AdminRoute path="/admin/redeem-transaction" component={RedeemTransaction} />
        <AdminRoute path="/admin/redeem-transaction-success" component={RedeemTransactionSuccess} />
        <AdminRoute path="/admin/dashboard/morale-kpi" component={MoraleKpi} />
        <AdminRoute path="/admin/dashboard/cost-reward" component={CostReward} />
        <AdminRoute path="/admin/dashboard/transfer" component={Transfer} />
        <AdminRoute path="/admin/dashboard/special-coin" component={SpecialCoin} />
        <AdminRoute path="/admin/manage/reward" component={RewardManage} />
        <AdminRoute path="/admin/manage/activity" component={ActivityManage} />
        <AdminRoute path="/admin/manage/activity-register/:id" component={ActivityRegister} />
        <AdminRoute path="/admin/manage/create-activity" component={CreateActivity} />
        <AdminRoute path="/admin/manage/create-activity-alt" component={CreateActivityAlt} />
        <AdminRoute path="/admin/manage/edit-activity/:id" component={EditActivity} />
        <AdminRoute path="/admin/manage/notification" component={NotificationManage} />
        <AdminRoute path="/admin/manage/moraleDaily" component={MoraleDaily} />
        <AdminRoute path="/admin/manage/moraleDaily/:id" component={MoraleDailyAnswer} />
        <AdminRoute path="/admin/manage/morale-question/:id" component={MoraleDescriptionsManage} />
        <AdminRoute path="/admin/manage/morale-question" component={MoraleQuestionManage} />
        <AdminRoute path="/admin/manage/poll" component={PollManage} />
        <AdminRoute path="/admin/manage/assessment360" component={Assessment360} />
        <AdminRoute path="/admin/manage/createassessment360" component={CreateAssessment360} />
        <AdminRoute path="/admin/manage/assessment360/:id" component={Assessment360Descriptions} />
        <AdminRoute path="/admin/manage/editassessment360/:id" component={EditAssessment360} />
        <AdminRoute path="/admin/assessment360" component={AssignAssessment360} />
        <AdminRoute path="/admin/assessment360/answer/:id" component={AnswerAssessment360} />
        <AdminRoute path="/admin/user-answerassessment360" component={UserAnswerAssessment360} />
        <AdminRoute path="/admin/announcement" component={Announcement} />
        <AdminRoute path="/admin/createAnnouncement" component={CreateAnnouncement} />
        <AdminRoute path="/admin/communication" component={BoardAdmin} />
        <AdminRoute path="/admin/report" component={Report} />
        <AdminRoute path="/admin/company-send-coin" component={CompanySendCoin} /> */}
      </Route>
      <Route path="uni-team" element={<RequireAuth allowedRoles={[9]} /> }>
        <Route path="reward">
          <Route index element={<UniTeamReward />} />
          <Route path="create" element={<CreateUniReward />} />
          <Route path="edit/:id" element={<EditUniReward />} />
          <Route path="detail/:id" element={<DetailUniReward />} />
          <Route path="transaction/:id" element={<UniRewardTransaction />} />
          <Route path="sale-history" element={<UniRewardSaleHistory />} />
          <Route path="sale-history/:id" element={<UniRewardTransaction />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default Routers;
