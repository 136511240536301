export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const USERS_FETCHING = "USERS_FETCHING";
export const USERS_FAILED = "USERS_FAILED";
export const USERS_SUCCESS = "USERS_SUCCESS";

export const USERPROFILE_FETCHING = "USERPROFILE_FETCHING";
export const USERPROFILE_FAILED = "USERPROFILE_FAILED";
export const USERPROFILE_SUCCESS = "USERPROFILE_SUCCESS";

export const COACH_PROFILE_FETCHING = "COACH_PROFILE_FETCHING";
export const COACH_PROFILE_FAILED = "COACH_PROFILE_FAILED";
export const COACH_PROFILE_SUCCESS = "COACH_PROFILE_SUCCESS";

export const COURSE_FETCHING = "COURSE_FETCHING";
export const COURSE_FAILED = "COURSE_FAILED";
export const COURSE_SUCCESS = "COURSE_SUCCESS";

export const DEPARTMENT_FETCHING = "DEPARTMENT_FETCHING";
export const DEPARTMENT_FAILED = "DEPARTMENT_FAILED";
export const DEPARTMENT_SUCCESS = "DEPARTMENT_SUCCESS";

export const COURSE_ITEM_FETCHING = "COURSE_ITEM_FETCHING";
export const COURSE_ITEM_FAILED = "COURSE_ITEM_FAILED";
export const COURSE_ITEM_SUCCESS = "COURSE_ITEM_SUCCESS";

export const HEARTTRANSFER_FETCHING = "HEARTTRANSFER_FETCHING";
export const HEARTTRANSFER_FAILED = "HEARTTRANSFER_FAILED";
export const HEARTTRANSFER_SUCCESS = "HEARTTRANSFER_SUCCESS";

export const HEART_RECEIVE_FETCHING = "HEART_RECEIVE_FETCHING";
export const HEART_RECEIVE_FAILED = "HEART_RECEIVE_FAILED";
export const HEART_RECEIVE_SUCCESS = "HEART_RECEIVE_SUCCESS";

export const HEART_SEND_MONTH_FETCHING = "HEART_SEND_MONTH_FETCHING";
export const HEART_SEND_MONTH_FAILED = "HEART_SEND_MONTH_FAILED";
export const HEART_SEND_MONTH_SUCCESS = "HEART_SEND_MONTH_SUCCESS";

export const HEART_RECEIVE_YEAR_FETCHING = "HEART_RECEIVE_YEAR_FETCHING";
export const HEART_RECEIVE_YEAR_FAILED = "HEART_RECEIVE_YEAR_FAILED";
export const HEART_RECEIVE_YEAR_SUCCESS = "HEART_RECEIVE_YEAR_SUCCESS";

export const HEART_LEADERBOARD_FETCHING = "HEART_LEADERBOARD_FETCHING";
export const HEART_LEADERBOARD_FAILED = "HEART_LEADERBOARD_FAILED";
export const HEART_LEADERBOARD_SUCCESS = "HEART_LEADERBOARD_SUCCESS";

export const COIN_LEADERBOARD_FETCHING = "COIN_LEADERBOARD_FETCHING";
export const COIN_LEADERBOARD_FAILED = "COIN_LEADERBOARD_FAILED";
export const COIN_LEADERBOARD_SUCCESS = "COIN_LEADERBOARD_SUCCESS";

export const MANAGER_HEART_LEADERBOARD_FETCHING = "MANAGER_HEART_LEADERBOARD_FETCHING";
export const MANAGER_HEART_LEADERBOARD_FAILED = "MANAGER_HEART_LEADERBOARD_FAILED";
export const MANAGER_HEART_LEADERBOARD_SUCCESS = "MANAGER_HEART_LEADERBOARD_SUCCESS";

export const QUESTION_FETCHING = "QUESTION_FETCHING";
export const QUESTION_FAILED = "QUESTION_FAILED";
export const QUESTION_SUCCESS = "QUESTION_SUCCESS";

export const MORALEQUESTIONS_FETCHING = "MORALEQUESTIONS_FETCHING";
export const MORALEQUESTIONS_FAILED = "MORALEQUESTIONS_FAILED";
export const MORALEQUESTIONS_SUCCESS = "MORALEQUESTIONS_SUCCESS";

export const MORALECOMPARE_FETCHING = "MORALECOMPARE_FETCHING";
export const MORALECOMPARE_FAILED = "MORALECOMPARE_FAILED";
export const MORALECOMPARE_SUCCESS = "MORALECOMPARE_SUCCESS";

export const MORALEQUESTION_ITEM_FETCHING = "MORALEQUESTION_ITEM_FETCHING";
export const MORALEQUESTION_ITEM_FAILED = "MORALEQUESTION_ITEM_FAILED";
export const MORALEQUESTION_ITEM_SUCCESS = "MORALEQUESTION_ITEM_SUCCESS";

export const MORALEQUESTION_USER_FETCHING = "MORALEQUESTION_USER_FETCHING";
export const MORALEQUESTION_USER_FAILED = "MORALEQUESTION_USER_FAILED";
export const MORALEQUESTION_USER_SUCCESS = "MORALEQUESTION_USER_SUCCESS";

export const MORALEQUESTION_KPI_FETCHING = "MORALEQUESTION_KPI_FETCHING";
export const MORALEQUESTION_KPI_FAILED = "MORALEQUESTION_KPI_FAILED";
export const MORALEQUESTION_KPI_SUCCESS = "MORALEQUESTION_KPI_SUCCESS";

export const MORALEQUESTION_KPI_ITEM_FETCHING = "MORALEQUESTION_KPI_ITEM_FETCHING";
export const MORALEQUESTION_KPI_ITEM_FAILED = "MORALEQUESTION_KPI_ITEM_FAILED";
export const MORALEQUESTION_KPI_ITEM_SUCCESS = "MORALEQUESTION_KPI_ITEM_SUCCESS";

export const MORALEQUESTION_KPI_YEAR_FETCHING = "MORALEQUESTION_KPI_YEAR_FETCHING";
export const MORALEQUESTION_KPI_YEAR_FAILED = "MORALEQUESTION_KPI_YEAR_FAILED";
export const MORALEQUESTION_KPI_YEAR_SUCCESS = "MORALEQUESTION_KPI_YEAR_SUCCESS";

export const MORALEQUESTION_KPI_YEAR_ITEM_FETCHING = "MORALEQUESTION_KPI_YEAR_ITEM_FETCHING";
export const MORALEQUESTION_KPI_YEAR_ITEM_FAILED = "MORALEQUESTION_KPI_YEAR_ITEM_FAILED";
export const MORALEQUESTION_KPI_YEAR_ITEM_SUCCESS = "MORALEQUESTION_KPI_YEAR_ITEM_SUCCESS";

export const REWARDS_FETCHING = "REWARDS_FETCHING";
export const REWARDS_FAILED = "REWARDS_FAILED";
export const REWARDS_SUCCESS = "REWARDS_SUCCESS";

export const REWARD_ITEM_FETCHING = "REWARD_ITEM_FETCHING";
export const REWARD_ITEM_FAILED = "REWARD_ITEM_FAILED";
export const REWARD_ITEM_SUCCESS = "REWARD_ITEM_SUCCESS";

export const REWARD_BESTSELLER_FETCHING = "REWARD_BESTSELLER_FETCHING";
export const REWARD_BESTSELLER_FAILED = "REWARD_BESTSELLER_FAILED";
export const REWARD_BESTSELLER_SUCCESS = "REWARD_BESTSELLER_SUCCESS";

export const ACTIVITY_FETCHING = "ACTIVITY_FETCHING";
export const ACTIVITY_FAILED = "ACTIVITY_FAILED";
export const ACTIVITY_SUCCESS = "ACTIVITY_SUCCESS";

export const ACTIVITY_ITEM_FETCHING = "ACTIVITY_ITEM_FETCHING";
export const ACTIVITY_ITEM_FAILED = "ACTIVITY_ITEM_FAILED";
export const ACTIVITY_ITEM_SUCCESS = "ACTIVITY_ITEM_SUCCESS";

export const ACTIVITY_HOT_FETCHING = "ACTIVITY_HOT_FETCHING";
export const ACTIVITY_HOT_FAILED = "ACTIVITY_HOT_FAILED";
export const ACTIVITY_HOT_SUCCESS = "ACTIVITY_HOT_SUCCESS";

export const MY_REWARD_FETCHING = "MY_REWARD_FETCHING";
export const MY_REWARD_FAILED = "MY_REWARD_FAILED";
export const MY_REWARD_SUCCESS = "MY_REWARD_SUCCESS";

export const ACTIVITY_REGISTER_FETCHING = "ACTIVITY_REGISTER_FETCHING";
export const ACTIVITY_REGISTER_FAILED = "ACTIVITY_REGISTER_FAILED";
export const ACTIVITY_REGISTER_SUCCESS = "ACTIVITY_REGISTER_SUCCESS";

export const ALERT_DAILY_INSIGHT_OPEN = "ALERT_DAILY_INSIGHT_OPEN";
export const ALERT_DAILY_INSIGHT_CLOSE = "ALERT_DAILY_INSIGHT_CLOSE";

export const CATEGORY_FETCHING = "CATEGORY_FETCHING";
export const CATEGORY_FAILED = "CATEGORY_FAILED";
export const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";

export const BOOK_FETCHING = "BOOK_FETCHING";
export const BOOK_FAILED = "BOOK_FAILED";
export const BOOK_SUCCESS = "BOOK_SUCCESS";

export const BOOK_COACH_FETCHING = "BOOK_COACH_FETCHING";
export const BOOK_COACH_FAILED = "BOOK_COACH_FAILED";
export const BOOK_COACH_SUCCESS = "BOOK_COACH_SUCCESS";

export const BOOK_COACHEE_FETCHING = "BOOK_COACHEE_FETCHING";
export const BOOK_COACHEE_FAILED = "BOOK_COACHEE_FAILED";
export const BOOK_COACHEE_SUCCESS = "BOOK_COACHEE_SUCCESS";

export const COACH_FETCHING = "COACH_FETCHING";
export const COACH_FAILED = "COACH_FAILED";
export const COACH_SUCCESS = "COACH_SUCCESS";

export const COACH_ITEM_FETCHING = "COACH_ITEM_FETCHING";
export const COACH_ITEM_FAILED = "COACH_ITEM_FAILED";
export const COACH_ITEM_SUCCESS = "COACH_ITEM_SUCCESS";

export const COACH_POPULAR_FETCHING = "COACH_POPULAR_FETCHING";
export const COACH_POPULAR_FAILED = "COACH_POPULAR_FAILED";
export const COACH_POPULAR_SUCCESS = "COACH_POPULAR_SUCCESS";

export const COACH_RECORD_FETCHING = "COACH_RECORD_FETCHING";
export const COACH_RECORD_FAILED = "COACH_RECORD_FAILED";
export const COACH_RECORD_SUCCESS = "COACH_RECORD_SUCCESS";

export const COACH_HISTORY_FETCHING = "COACH_HISTORY_FETCHING";
export const COACH_HISTORY_FAILED = "COACH_HISTORY_FAILED";
export const COACH_HISTORY_SUCCESS = "COACH_HISTORY_SUCCESS";

export const COACHEE_FETCHING = "COACHEE_FETCHING";
export const COACHEE_FAILED = "COACHEE_FAILED";
export const COACHEE_SUCCESS = "COACHEE_SUCCESS";

export const COACHEE_HISTORY_FETCHING = "COACHEE_HISTORY_FETCHING";
export const COACHEE_HISTORY_FAILED = "COACHEE_HISTORY_FAILED";
export const COACHEE_HISTORY_SUCCESS = "COACHEE_HISTORY_SUCCESS";

export const COACH_REVIEW_FETCHING = "COACH_REVIEW_FETCHING";
export const COACH_REVIEW_FAILED = "COACH_REVIEW_FAILED";
export const COACH_REVIEW_SUCCESS = "COACH_REVIEW_SUCCESS";

export const COACH_REVIEW_ITEM_FETCHING = "COACH_REVIEW_ITEM_FETCHING";
export const COACH_REVIEW_ITEM_FAILED = "COACH_REVIEW_ITEM_FAILED";
export const COACH_REVIEW_ITEM_SUCCESS = "COACH_REVIEW_ITEM_SUCCESS";

export const FAVORITE_COACH_FETCHING = "FAVORITE_COACH_FETCHING";
export const FAVORITE_COACH_FAILED = "FAVORITE_COACH_FAILED";
export const FAVORITE_COACH_SUCCESS = "FAVORITE_COACH_SUCCESS";

export const ASSESSMENT_FETCHING = "ASSESSMENT_FETCHING";
export const ASSESSMENT_FAILED = "ASSESSMENT_FAILED";
export const ASSESSMENT_SUCCESS = "ASSESSMENT_SUCCESS";

export const ASSESSMENT_ITEM_FETCHING = "ASSESSMENT_ITEM_FETCHING";
export const ASSESSMENT_ITEM_FAILED = "ASSESSMENT_ITEM_FAILED";
export const ASSESSMENT_ITEM_SUCCESS = "ASSESSMENT_ITEM_SUCCESS";

export const ASSESSMENT_ANSWER_FETCHING = "ASSESSMENT_ANSWER_FETCHING";
export const ASSESSMENT_ANSWER_FAILED = "ASSESSMENT_ANSWER_FAILED";
export const ASSESSMENT_ANSWER_SUCCESS = "ASSESSMENT_ANSWER_SUCCESS";

export const APPRAISEE_FETCHING = "APPRAISEE_FETCHING";
export const APPRAISEE_FAILED = "APPRAISEE_FAILED";
export const APPRAISEE_SUCCESS = "APPRAISEE_SUCCESS";

export const APPRAISEE_ITEM_FETCHING = "APPRAISEE_ITEM_FETCHING";
export const APPRAISEE_ITEM_FAILED = "APPRAISEE_ITEM_FAILED";
export const APPRAISEE_ITEM_SUCCESS = "APPRAISEE_ITEM_SUCCESS";

export const APPRAISEE_USER_FETCHING = "APPRAISEE_USER_FETCHING";
export const APPRAISEE_USER_FAILED = "APPRAISEE_USER_FAILED";
export const APPRAISEE_USER_SUCCESS = "APPRAISEE_USER_SUCCESS";

export const DATA_TOPIC_FETCHING = "DATA_TOPIC_FETCHING";
export const DATA_TOPIC_FAILED = "DATA_TOPIC_FAILED";
export const DATA_TOPIC_SUCCESS = "DATA_TOPIC_SUCCESS";

export const SPECIAL_COIN_FETCHING = "SPECIAL_COIN_FETCHING";
export const SPECIAL_COIN_FAILED = "SPECIAL_COIN_FAILED";
export const SPECIAL_COIN_SUCCESS = "SPECIAL_COIN_SUCCESS";

export const NOTIFICATION_FETCHING = "NOTIFICATION_FETCHING";
export const NOTIFICATION_FAILED = "NOTIFICATION_FAILED";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";

export const REDEEM_TRANSACTIONS_FETCHING = "REDEEM_TRANSACTIONS_FETCHING";
export const REDEEM_TRANSACTIONS_FAILED = "REDEEM_TRANSACTIONS_FAILED";
export const REDEEM_TRANSACTIONS_SUCCESS = "REDEEM_TRANSACTIONS_SUCCESS";

export const MISSION_FETCHING = "MISSION_FETCHING";
export const MISSION_FAILED = "MISSION_FAILED";
export const MISSION_SUCCESS = "MISSION_SUCCESS";

export const POLL_FETCHING = "POLL_FETCHING";
export const POLL_FAILED = "POLL_FAILED";
export const POLL_SUCCESS = "POLL_SUCCESS";

export const ANSWER_SCALE_FETCHING = "ANSWER_SCALE_FETCHING";
export const ANSWER_SCALE_FAILED = "ANSWER_SCALE_FAILED";
export const ANSWER_SCALE_SUCCESS = "ANSWER_SCALE_SUCCESS";

export const QUESTION_TOPIC_FETCHING = "QUESTION_TOPIC_FETCHING";
export const QUESTION_TOPIC_FAILED = "QUESTION_TOPIC_FAILED";
export const QUESTION_TOPIC_SUCCESS = "QUESTION_TOPIC_SUCCESS";

export const MORALE_DAILYQUESTIONS_FETCHING = "MORALE_DAILYQUESTIONS_FETCHING";
export const MORALE_DAILYQUESTIONS_FAILED = "MORALE_DAILYQUESTIONS_FAILED";
export const MORALE_DAILYQUESTIONS_SUCCESS = "MORALE_DAILYQUESTIONS_SUCCESS";

export const MORALE_DAILY_FETCHING = "MORALE_DAILY_FETCHING";
export const MORALE_DAILY_FAILED = "MORALE_DAILY_FAILED";
export const MORALE_DAILY_SUCCESS = "MORALE_DAILY_SUCCESS";

export const MORALE_DAILY_ITEM_FETCHING = "MORALE_DAILY_ITEM_FETCHING";
export const MORALE_DAILY_ITEM_FAILED = "MORALE_DAILY_ITEM_FAILED";
export const MORALE_DAILY_ITEM_SUCCESS = "MORALE_DAILY_ITEM_SUCCESS";

export const MORALE_DAILY_USER_FETCHING = "MORALE_DAILY_USER_FETCHING";
export const MORALE_DAILY_USER_FAILED = "MORALE_DAILY_USER_FAILED";
export const MORALE_DAILY_USER_SUCCESS = "MORALE_DAILY_USER_SUCCESS";

export const MORALE_DAILY_CURRENT_FETCHING = "MORALE_DAILY_CURRENT_FETCHING";
export const MORALE_DAILY_CURRENT_FAILED = "MORALE_DAILY_CURRENT_FAILED";
export const MORALE_DAILY_CURRENT_SUCCESS = "MORALE_DAILY_CURRENT_SUCCESS";

export const FEEDBACK_FETCHING = "FEEDBACK_FETCHING";
export const FEEDBACK_FAILED = "FEEDBACK_FAILED";
export const FEEDBACK_SUCCESS = "FEEDBACK_SUCCESS";

export const ACTIVITY_PARTICIPANT_FETCHING = "ACTIVITY_PARTICIPANT_FETCHING";
export const ACTIVITY_PARTICIPANT_FAILED = "ACTIVITY_PARTICIPANT_FAILED";
export const ACTIVITY_PARTICIPANT_SUCCESS = "ACTIVITY_PARTICIPANT_SUCCESS";

export const ANNOUNCEMENT_FETCHING = "ANNOUNCEMENT_FETCHING";
export const ANNOUNCEMENT_FAILED = "ANNOUNCEMENT_FAILED";
export const ANNOUNCEMENT_SUCCESS = "ANNOUNCEMENT_SUCCESS";

export const COMMUNICATION_FETCHING = "COMMUNICATION_FETCHING";
export const COMMUNICATION_FAILED = "COMMUNICATION_FAILED";
export const COMMUNICATION_SUCCESS = "COMMUNICATION_SUCCESS";

export const ANALYTIC_FETCHING = "ANALYTIC_FETCHING";
export const ANALYTIC_FAILED = "ANALYTIC_FAILED";
export const ANALYTIC_SUCCESS = "ANALYTIC_SUCCESS";

export const COMPANY_SEND_COIN_FETCHING = "COMPANY_SEND_COIN_FETCHING";
export const COMPANY_SEND_COIN_FAILED = "COMPANY_SEND_COIN_FAILED";
export const COMPANY_SEND_COIN_SUCCESS = "COMPANY_SEND_COIN_SUCCESS";

// export const TRANSFER_HEART_IN_FETCHING = "TRANSFER_HEART_IN_FETCHING";
// export const TRANSFER_HEART_IN_FAILED = "TRANSFER_HEART_IN_FAILED";
// export const TRANSFER_HEART_IN_SUCCESS = "TRANSFER_HEART_IN_SUCCESS";

export const TRANSFER_HEART_OUT_FETCHING = "TRANSFER_HEART_OUT_FETCHING";
export const TRANSFER_HEART_OUT_FAILED = "TRANSFER_HEART_OUT_FAILED";
export const TRANSFER_HEART_OUT_SUCCESS = "TRANSFER_HEART_OUT_SUCCESS";

// export const TRANSFER_COIN_IN_FETCHING = "TRANSFER_COIN_IN_FETCHING";
// export const TRANSFER_COIN_IN_FAILED = "TRANSFER_COIN_IN_FAILED";
// export const TRANSFER_COIN_IN_SUCCESS = "TRANSFER_COIN_IN_SUCCES";

// export const TRANSFER_COIN_OUT_ACTIVITY_FETCHING = "TRANSFER_COIN_OUT_ACTIVITY_FETCHING";
// export const TRANSFER_COIN_OUT_ACTIVITY_FAILED = "TRANSFER_COIN_OUT_ACTIVITY_FAILED";
// export const TRANSFER_COIN_OUT_ACTIVITY_SUCCESS = "TRANSFER_COIN_OUT_ACTIVITY_SUCCESS";

export const TRANSFER_COIN_OUT_ANNOUNCEMENT_FETCHING = "TRANSFER_COIN_OUT_ANNOUNCEMENT_FETCHING";
export const TRANSFER_COIN_OUT_ANNOUNCEMENT_FAILED = "TRANSFER_COIN_OUT_ANNOUNCEMENT_FAILED";
export const TRANSFER_COIN_OUT_ANNOUNCEMENT_SUCCESS = "TRANSFER_COIN_OUT_ANNOUNCEMENT_SUCCESS";

export const TRANSFER_COIN_OUT_COMMU_FETCHING = "TRANSFER_COIN_OUT_COMMU_FETCHING";
export const TRANSFER_COIN_OUT_COMMU_FAILED = "TRANSFER_COIN_OUT_COMMU_FAILED";
export const TRANSFER_COIN_OUT_COMMU_SUCCESS = "TRANSFER_COIN_OUT_COMMU_SUCCESS";

export const TRANSFER_COIN_OUT_MISSION_FETCHING = "TRANSFER_COIN_OUT_MISSION_FETCHING";
export const TRANSFER_COIN_OUT_MISSION_FAILED = "TRANSFER_COIN_OUT_MISSION_FAILED";
export const TRANSFER_COIN_OUT_MISSION_SUCCESS = "TRANSFER_COIN_OUT_MISSION_SUCCESS";

export const TRANSFER_COIN_OUT_MORALE_DAILY_FETCHING = "TRANSFER_COIN_OUT_MORALE_DAILY_FETCHING";
export const TRANSFER_COIN_OUT_MORALE_DAILY_FAILED = "TRANSFER_COIN_OUT_MORALE_DAILY_FAILED";
export const TRANSFER_COIN_OUT_MORALE_DAILY_SUCCESS = "TRANSFER_COIN_OUT_MORALE_DAILY_SUCCESS";

export const TOKENOMIC_FETCHING = "TOKENOMIC_FETCHING";
export const TOKENOMIC_FAILED = "TOKENOMIC_FAILED";
export const TOKENOMIC_SUCCESS = "TOKENOMIC_SUCCESS";

//COMPANY REWARD
export const COMPANY_REWARD_FETCHING = "COMPANY_REWARD_FETCHING";
export const COMPANY_REWARD_FAILED = "COMPANY_REWARD_FAILED";
export const COMPANY_REWARD_SUCCESS = "COMPANY_REWARD_SUCCESS";

export const COMPANY_REWARD_ITEM_FETCHING = "COMPANY_REWARD_ITEM_FETCHING";
export const COMPANY_REWARD_ITEM_FAILED = "COMPANY_REWARD_ITEM_FAILED";
export const COMPANY_REWARD_ITEM_SUCCESS = "COMPANY_REWARD_ITEM_SUCCESS";

//UNI REWARD
export const UNI_REWARD_FETCHING = "UNI_REWARD_FETCHING";
export const UNI_REWARD_FAILED = "UNI_REWARD_FAILED";
export const UNI_REWARD_SUCCESS = "UNI_REWARD_SUCCESS";

export const UNI_REWARD_ITEM_FETCHING = "UNI_REWARD_ITEM_FETCHING";
export const UNI_REWARD_ITEM_FAILED = "UNI_REWARD_ITEM_FAILED";
export const UNI_REWARD_ITEM_SUCCESS = "UNI_REWARD_ITEM_SUCCESS";

export const UNI_REWARD_CART_FETCHING = "UNI_REWARD_CART_FETCHING";
export const UNI_REWARD_CART_FAILED = "UNI_REWARD_CART_FAILED";
export const UNI_REWARD_CART_SUCCESS = "UNI_REWARD_CART_SUCCESS";

export const UNI_REWARD_ORDER_FETCHING = "UNI_REWARD_ORDER_FETCHING";
export const UNI_REWARD_ORDER_FAILED = "UNI_REWARD_ORDER_FAILED";
export const UNI_REWARD_ORDER_SUCCESS = "UNI_REWARD_ORDER_SUCCESS";

export const AFFILIATE_ORGANIZATION_FETCHING = "AFFILIATE_ORGANIZATION_FETCHING";
export const AFFILIATE_ORGANIZATION_FAILED = "AFFILIATE_ORGANIZATION_FAILED";
export const AFFILIATE_ORGANIZATION_SUCCESS = "AFFILIATE_ORGANIZATION_SUCCESS";

export const USER_COIN_FETCHING = "USER_COIN_FETCHING";
export const USER_COIN_FAILED = "USER_COIN_FAILED";
export const USER_COIN_SUCCESS = "USER_COIN_SUCCESS";

export const USER_COIN_QUOTA_FETCHING = "USER_COIN_QUOTA_FETCHING";
export const USER_COIN_QUOTA_FAILED = "USER_COIN_QUOTA_FAILED";
export const USER_COIN_QUOTA_SUCCESS = "USER_COIN_QUOTA_SUCCESS";