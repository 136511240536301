import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Chip, CircularProgress, Typography } from "@mui/material";
import {
  useCancelRedeemTransaction,
  useRedeemTransactions,
  useUpdateRedeemTransaction,
} from "../../../../quries/redeemTransaction";
import monthOnlyOperators from "../../shared/dataGridFilter/monthOnlyOperators";
import dayjs from "dayjs";
import { toast } from "sonner";
import StatusMenu from "./StatusMenu";
import DialogStatus from "./DialogStatus";
import { useSelector } from "react-redux";

const StyledDataGrid = styled(DataGrid)({
  "&.MuiDataGrid-root": {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      border: "none",
    },
    "& .MuiDataGrid-cell": {
      border: "none",
    },
  },
  "& .super-app-theme--header": {
    backgroundColor: "#f4f6f8",
    color: "#637381",
  },
});

const redeemStatus = {
  1: { status: "กำลังตรวจสอบ", color: "#1e88e5" },
  2: { status: "รอมารับรางวัล", color: "#fb8c00" },
  3: { status: "รับรางวัลแล้ว", color: "#43a047" },
  4: { status: "ยกเลิกรายการ", color: "#e53935" },
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: "redeemTransaction",
          delimiter: ",",
          fields: [
            "id",
            "fullName",
            "name",
            "price",
            "quantity",
            "totalValue",
            "redeemDate",
          ],
          utf8WithBom: true,
        }}
        printOptions={{ disableToolbarButton: true }}
      />
    </GridToolbarContainer>
  );
}

const RedeemTransactionGrid = () => {
  const [openChangeDate, setOpenChangeDate] = useState({
    isOpen: false,
    redeemSelected: null,
  });

  const handleOpenDate = (redeemSelected) => {
    setOpenChangeDate({
      isOpen: true,
      redeemSelected,
    })
  };

  const handleCloseDate = () => {
    setOpenChangeDate({
      isOpen: false,
      redeemSelected: null,
    })
  };

  const { result: userProfile } = useSelector(state => state.userProfile);
  const { isPending, data } = useRedeemTransactions({ idCompany: userProfile?.idCompany });
  const { mutate: updateRedeemTransaction } = useUpdateRedeemTransaction();
  const { mutate: cancelRedeemTransaction } = useCancelRedeemTransaction();

  const handleChangeStatusRedeem = (formData) => {
    updateRedeemTransaction(formData, {
      onSuccess: () => toast.success("สำเร็จ"),
      onError: () => toast.error("เกิดข้อผิดพลาด"),
      onSettled: () => handleCloseDate(),
    });
  };

  const cancelStatusRedeem = (idRedeem) => {
    cancelRedeemTransaction(idRedeem, {
      onSuccess: () => toast.success("สำเร็จ"),
      onError: () => toast.error("เกิดข้อผิดพลาด"),
    });
  };

  const columns = [
    {
      field: "idRedeem",
      headerName: "รหัสรายการ",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "fullName",
      headerName: "ชื่อผู้แลกของรางวัล",
      flex: 2,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <Typography>{`${params.row.firstname_TH} ${params.row.lastname_TH}`}</Typography>
      ),
    },
    {
      field: "name",
      headerName: "ชื่อของรางวัล",
      flex: 2,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "price",
      headerName: "มูลค่า/หน่วย",
      type: "number",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "quantity",
      headerName: "จำนวน",
      type: "number",
      flex: 1,
      headerClassName: "super-app-theme--header",
      filterable: false,
    },
    {
      field: "totalValue",
      headerName: "มูลค่ารวม",
      type: "number",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row.price * params.row.quantity,
    },
    {
      field: "redeemDate",
      headerName: "วันที่แลกรางวัล",
      type: "date",
      flex: 1.5,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => dayjs(params.value),
      valueFormatter: ({ value }) => dayjs(value).format("DD-MM-YYYY"),
      renderCell: (params) => dayjs(params.value).format("DD-MM-YYYY HH:mm"),
      filterOperators: monthOnlyOperators,
    },
    {
      field: "receivedDate",
      headerName: "วันที่ได้รับรางวัล",
      type: "date",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => dayjs(params.value),
      valueFormatter: ({ value }) =>
        value ? dayjs(value).format("DD-MM-YYYY") : "ยังไม่กำหนด",
      renderCell: (params) =>
        dayjs(params.value).isValid()
          ? dayjs(params.value).format("DD-MM-YYYY")
          : "ยังไม่กำหนด",
      filterOperators: monthOnlyOperators,
    },
    {
      field: "idRedeemStatus",
      headerName: "สถานะ",
      flex: 1,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Chip
            icon={
              <Brightness1Icon
                style={{
                  color: redeemStatus[params.row.idRedeemStatus].color,
                }}
              />
            }
            label={redeemStatus[params.row.idRedeemStatus].status}
            variant="outlined"
          />
        );
      },
      sortable: false,
      filterable: false,
    },
    {
      field: "actions",
      headerName: "จัดการ",
      type: "actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return params.row.idRedeemStatus === 1 ||
          params.row.idRedeemStatus === 2 ? (
          <StatusMenu
            row={params.row}
            handleChangeStatusRedeem={handleChangeStatusRedeem}
            cancelStatusRedeem={cancelStatusRedeem}
            handleOpenDate={handleOpenDate}
          />
        ) : null;
      },
    },
  ];

  if (isPending) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <StyledDataGrid
        getRowId={(row) => row.idRedeem}
        rows={data}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
        pageSizeOptions={[10, 25, 100]}
        disableRowSelectionOnClick
        autoHeight
      />
      {openChangeDate.isOpen && (
        <DialogStatus
          openChangeDate={openChangeDate.isOpen}
          redeemSelected={openChangeDate.redeemSelected}
          handleCloseDate={handleCloseDate}
          handleChangeStatusRedeem={handleChangeStatusRedeem}
        />
      )}
    </>
  );
};

export default RedeemTransactionGrid;
