import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import {
  Typography,
  Paper,
  Button,
  Container,
  Grid,
  Alert,
  Stack,
} from "@mui/material";

import HeaderPage from "../../../pages/shared/header/headerPage";
import MoraleQuestionIcon from "./assets/question.png";
import { getMoraleUser } from "../../../../actions/moraleQuestion";
import LoadingIcon from "../../shared/general/loadingIcon";


const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF",
  "& .wrapHeader": {
    display: "flex",
    alignItems: "center",
    marginBottom: 36,
    ["@media only screen and (max-width:600px)"]: {
      flexDirection: "column",
    },
  },
});

const WrapCardQuestion = styled(Paper)({
  width: "100%",
  display: "flex",
  borderRadius: 15,
  marginBottom: 20,
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
})

const StyledBtnDescPublish = styled(Button)({
  border: 0,
  borderRadius: 50,
  padding: "4px 12px",
  fontSize: 14,
  backgroundColor: "#2A265F",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#2a265f",
    borderColor: "#2a265f",
    boxShadow: "none",
  },
  ["@media only screen and (max-width:600px)"]: {
    position: "initial",
    marginTop: 16,
    width: "100%"
  },
})

const StyledBtnDescDraft = styled(Button)({
  border: 0,
  borderRadius: 50,
  padding: "4px 12px",
  fontSize: 14,
  backgroundColor: "#f8de55",
  color: "#3C3C3C",
  position: "absolute",
  bottom: 20,
  right: 20,
  "&:hover": {
    backgroundColor: "#f8de55",
    borderColor: "#f8de55",
    boxShadow: "none",
  },
  ["@media only screen and (max-width:600px)"]: {
    position: "initial",
    marginTop: 16,
    width: "100%"
  },
})

const StyledBtnDescClosed = styled(Button)({
  border: 0,
  borderRadius: 50,
  padding: "4px 12px",
  fontSize: 14,
  backgroundColor: "#BBBBBB",
  color: "#FFFFFF",
  position: "absolute",
  bottom: 20,
  right: 20,
  "&:hover": {
    backgroundColor: "#BBBBBB",
    borderColor: "#BBBBBB",
    boxShadow: "none",
  },
  ["@media only screen and (max-width:600px)"]: {
    position: "initial",
    marginTop: 16,
    width: "100%"
  },
})

const StyledBtnViewAnswer = styled(Typography,
  { shouldForwardProp: (prop) => prop !== 'myColor'})(
    ({ myColor }) => ({
    fontSize: 14,
    cursor: "pointer",
    color: myColor,
}))

const StyledPanelLeft = styled('div',
{ shouldForwardProp: (prop) => prop !== 'myBgColor' &&  prop !== 'myColor'})(
  ({ myBgColor, myColor }) => ({
    backgroundColor: myBgColor,
    width: "30%",
    padding: 20,
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .questionName": {
      fontSize: 32,
      color: myColor,
    },
    "& .subQuestionName": {
      fontSize: 14,
      color: myColor,
    },
    "& .wrapViewAnswer": {
      marginTop: 8,
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      color: myColor,
    },
    ["@media only screen and (max-width:600px)"]: {
      width: "100%",
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 15,
      "& .questionName": {
        fontSize: 24,
      },
      "& .subQuestionName": {
        fontSize: 16,
      },
    },
}))

const StyledPanelRight = styled('div')({
  width: "70%",
  padding: 20,
  color: "#2a265f",
  position: "relative",
  display: "flex",
  alignItems: "center",
  "& .inner-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .questionTopic":{
    display: "flex", alignItems: "baseline"
  },
  ["@media only screen and (max-width:600px)"]: {
    width: "100%",
    "& .inner-top": {
      flexDirection: "column",
      alignItems: "flex-end",
    },
    "& .questionTopic":{
      flexDirection: "column"
    }
  },
})

const StyledHeaderTopic = styled(Typography)({
  fontSize: 14,
  opacity: 0.6,
  color: "#000000",
  marginRight: 5,
})

const StyledContentTopic = styled(Typography)({
  fontSize: 16,
  color: "#3C3C3C",
  display: "flex",
  alignItems: "baseline",
})

const WrapQuestionStatusPublished = styled('div')({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 16,
})

const StyledQuestionStatusPublished = styled(Typography,
  { shouldForwardProp: (prop) => prop !== 'myColor'})(
    ({ myColor }) => ({
    fontSize: 12,
    textAlign: "right",
    paddingTop: 5,
    textTransform: "uppercase",
    color: myColor,
}))

const StyledSecondTextTopic = styled(Typography)({
  marginLeft: 5,
  textTransform: "uppercase",
  fontSize: 14,
  ["@media only screen and (max-width:600px)"]: {
    marginLeft: 22
  }
})

const StyledQuestionNumber = styled(Typography,
  { shouldForwardProp: (prop) => prop !== 'myColor'})(
    ({ myColor }) => ({
      fontSize: 14, 
      fontStyle: "oblique",
      color: myColor,
}))

const WrapFilter = styled('div')({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 40,
})

const AddQuestionButton = styled(Button)({
  marginRight: 8,
  fontSize: 20,
  backgroundColor: "#2A265F",
  "&:hover": {
    backgroundColor: "#353078",
  },
});

// const StyledDivider = styled(Divider)({
//   borderWidth: "0px 0px thin",
//   borderColor: "#919eab52",
//   borderStyle: "dashed",
// });

export default function MoraleQuestion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { result: moraleUser, isFetching } = useSelector(state => state.moraleUser)

  useEffect(() => {
    dispatch(getMoraleUser())
  }, []);

  const handleClickAnswerQuestion = (idMorale) => {
    navigate("/morale-question/" + idMorale);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div className={`wrapHeader`}>
          <HeaderPage textLabel={"คำถามสุขภาพใจรายปี"} icon={MoraleQuestionIcon} />
        </div>
        {isFetching ? (
          <LoadingIcon />
        ) : (
          moraleUser &&
          moraleUser.map((value) => {
            const numberOfQuestion = value.questionTopic.reduce(
              (accumulator, topic) => accumulator + topic.questionList.length,
              0
            );
            return (
              <WrapCardQuestion key={value.idMorale}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  sx={{ width: "100%" }}
                >
                  <StyledPanelLeft myBgColor={"#2A265F"} myColor={"#FFFFFF"}>
                    <div className={`inner-section-top`}>
                      <Typography className={`questionName`}>
                        {`${value.moraleName}`}
                      </Typography>
                    </div>
                  </StyledPanelLeft>
                  <StyledPanelRight>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <StyledContentTopic>
                          {`จำนวนหัวข้อ : ${value.questionTopic.length}`}
                        </StyledContentTopic>
                      </Grid>
                      <Grid item xs={6}>
                        <StyledContentTopic>
                          {`วันเริ่มต้น: ${dayjs(value.startDate).format(
                            "DD/MM/YYYY"
                          )}`}
                        </StyledContentTopic>
                      </Grid>
                      <Grid item xs={6}>
                        <StyledContentTopic>
                          {`จำนวนคำถามทั้งหมด: ${numberOfQuestion}  ข้อ`}
                        </StyledContentTopic>
                      </Grid>
                      <Grid item xs={6}>
                        <StyledContentTopic>
                          {`วันสิ้นสุด: ${dayjs(value.endDate).format(
                            "DD/MM/YYYY"
                          )}`}
                        </StyledContentTopic>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        alignItems={"center"}
                        gap={2}
                      >
                        {value.isComplete ? (
                          <Alert
                            severity="success"
                            sx={{ width: "fit-content" }}
                          >
                            ตอบคำถามแล้ว
                          </Alert>
                        ) : (
                          <Alert
                            severity="warning"
                            sx={{ width: "fit-content" }}
                          >
                            ยังไม่ได้ตอบคำถาม
                          </Alert>
                        )}
                        {dayjs().isBefore(dayjs(value.endDate)) &&
                          !value.isComplete && (
                            <StyledBtnDescPublish
                              onClick={() =>
                                handleClickAnswerQuestion(value.idMorale)
                              }
                              disabled={dayjs().isAfter(dayjs(value.endDate))}
                            >
                              ตอบคำถาม
                            </StyledBtnDescPublish>
                        )}
                      </Grid>
                    </Grid>
                  </StyledPanelRight>
                </Stack>
              </WrapCardQuestion>
            );
          })
        )}
      </Container>
    </StyledRoot>
  );
}