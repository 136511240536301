import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { httpClient } from "../services/httpClient";

export const useRewardType = () => {
  return useQuery({
    queryKey: ["rewardType"],
    queryFn: async () => (await httpClient.get(`reward-type`)).data,
    refetchOnWindowFocus: false,
  });
};

export const useUniRewardByCompany = () => {
  return useQuery({
    queryKey: ["reward", "uni-reward"],
    queryFn: async () => (await httpClient.get(`reward/uni-reward`)).data,
  });
};

export const useCompanyRewardByCompany = () => {
  return useQuery({
    queryKey: ["reward", "company-reward"],
    queryFn: async () => (await httpClient.get(`reward/company-reward`)).data,
  });
};

export const useCompanyRewardById = ({ idCompanyReward }) => {
  return useQuery({
    queryKey: ["reward", "company-reward", idCompanyReward],
    queryFn: async () => (await httpClient.get(`/reward/company-reward/${idCompanyReward}`)).data,
    enabled: Boolean(idCompanyReward),
  });
};

export const useUniRewardById = ({ idUniReward }) => {
  return useQuery({
    queryKey: ["reward", "uni-reward", idUniReward],
    queryFn: async () => (await httpClient.get(`/reward/uni-reward/${idUniReward}`)).data,
    enabled: Boolean(idUniReward),
  });
};

// export const useUpdateRewardCoin = ({ idReward }) => {
//   return useMutation({
//     mutationFn: async (formData) =>
//       await httpClient.patch(`reward/${idReward}/coin`, formData),
//   });
// };

export const useRewardCost = ({ idReward }) => {
  return useQuery({
    queryKey: ["reward", idReward, "cost"],
    queryFn: async () => (await httpClient.get(`/reward/${idReward}/cost`)).data,
    enabled: Boolean(idReward),
  });
};

// test
export const useStoreUniRewardItemCost = ({ idUniRewardItem }) => {
  return useQuery({
    queryKey: ["reward", "uni-reward-item", idUniRewardItem, "cost"],
    queryFn: async () => (await httpClient.get(`/reward/uni-reward-item/${idUniRewardItem}/cost`)).data,
    enabled: Boolean(idUniRewardItem),
  });
};

// test
export const useStoreCompanyRewardItemCost = ({ idCompanyRewardItem }) => {
  return useQuery({
    queryKey: ["reward", "company-reward-item", idCompanyRewardItem, "cost"],
    queryFn: async () => (await httpClient.get(`/reward/company-reward-item/${idCompanyRewardItem}/cost`)).data,
    enabled: Boolean(idCompanyRewardItem),
  });
};

export const useUpdateStoreRewardItem = ({ idReward, idUniRewardItem, idCompanyRewardItem }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData) =>
      await httpClient.patch(`/reward/store/${idReward}`, formData),
    onSuccess: () => {
      if (idUniRewardItem) {
        queryClient.invalidateQueries({
          queryKey: ["reward", "uni-reward-item", idUniRewardItem, "cost"]
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ["reward", "company-reward-item", idCompanyRewardItem, "cost"]
        });
      }
    },
  });
};

export const useUpdateUniRewardItemCoin = ({ idUniReward }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData) =>
      await httpClient.patch(
        `reward/uni-reward-item/${formData.idUniRewardItem}/coin`,
        formData
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["reward", "uni-reward", idUniReward],
      }),
  });
};

export const useUpdateCompanyRewardItemCoin = ({ idCompanyReward }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData) =>
      await httpClient.patch(
        `reward/company-reward-item/${formData.idCompanyRewardItem}/coin`,
        formData
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["reward", "company-reward", idCompanyReward],
      })
    }
  });
};

// export const useUpdateRewardStatus = () => {
//   return useMutation({
//     mutationFn: async (formData) =>
//       await httpClient.patch(`reward/${formData.idReward}/status`, formData),
//   });
// };

// export const useUpdateStoreUniRewardCost = ({ idReward, idOrderRewardItem }) => {
//   const queryClient = useQueryClient();
//   return useMutation({
//     mutationFn: async (formData) =>
//       await httpClient.patch(`/reward/uni-reward/cost/${idOrderRewardItem}`, formData),
//     onSuccess: () => {
//       queryClient.invalidateQueries({ queryKey: ["reward", idReward, "cost"] });
//     },
//   });
// };

// export const useUpdateStoreCompanyRewardCost = ({ idReward, idCompanyRewardCost }) => {
//   const queryClient = useQueryClient();
//   return useMutation({
//     mutationFn: async (formData) =>
//       await httpClient.patch(`/reward/company-reward/cost/${idCompanyRewardCost}`, formData),
//     onSuccess: () => {
//       queryClient.invalidateQueries({ queryKey: ["reward", idReward, "cost"] });
//     },
//   });
// };

export const useRewardsActive = ({ idCompany, select }) => {
  return useQuery({
    queryKey: ["rewards", "active"],
    queryFn: async () => (await httpClient.get(`reward-active/${idCompany}`)).data,
    enabled: Boolean(idCompany),
    select,
  });
};
  