import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  Typography,
  Divider,
  Paper,
  Stepper,
  Step,
  StepLabel,
  List,
  ListItem,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Container,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";

import FeelingIcon from "../../shared/feelingIcon";

import {
  addMoraleAnswer,
  addMoraleQuestionFeedback,
  getMorale,
} from "../../../../actions/moraleQuestion";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { addAnswerAppraisee } from "../../../../actions/appraisee";
import { getAnswerScale } from "../../../../actions/questionTopic";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 500,
});

const StyledTextDescription = styled(Typography)({
  marginTop: 5,
  marginBottom: 5,
});

const StyledGridHeaderAnswer = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: 8,
  marginBottom: 8,
  "& .answer": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    "& p": {
      fontSize: 14,
      fontWeight: 500,
      marginBottom: 8,
    },
  },
});

const StyledDivider= styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const StyledStepLabel = styled(StepLabel)({
  fontSize: 22,
  fontWeight: 400,
});

const WrapHeaderQurstion = styled("div")({
  backgroundColor: "#000000d6",
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  color: "white",
  padding: 10,
});

const WrapAnswer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const WrapStepper = styled("div")({
  margin: "40px 0px",
  overflow: "auto",
});

const answerQuestions5Scale = [
  {
    key: "verybad",
    label: "Very Bad",
    value: "0",
  },
  {
    key: "bad",
    label: "Bad",
    value: "25",
  },
  {
    key: "neutral",
    label: "Neutral",
    value: "50",
  },
  {
    key: "happy",
    label: "Happy",
    value: "75",
  },
  {
    key: "veryhappy",
    label: "Very Happy",
    value: "100",
  },
];

const answerQuestions6Scale = [
  {
    key: "verybad",
    label: "Very Bad",
    value: "0",
  },
  {
    key: "bad",
    label: "Bad",
    value: "20",
  },
  {
    key: "littlebad",
    label: "Little Bad",
    value: "40",
  },
  {
    key: "littlehappy",
    label: "Little Happy",
    value: "60",
  },
  {
    key: "happy",
    label: "Happy",
    value: "80",
  },
  {
    key: "veryhappy",
    label: "Very Happy",
    value: "100",
  },
];

const MoraleQuestionAnswer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { idMorale } = useParams();
  const { result: moraleQuestionItem } = useSelector(
    (state) => state.moraleQuestionItem
  );
  const { result: answerScale } = useSelector((state) => state.answerScale);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [answer, setAnswer] = useState({});
  const [feedback, setFeedback] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const steps = getSteps();

  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    dispatch(getMorale(parseInt(idMorale)));
    dispatch(getAnswerScale());
  }, []);

  function getSteps() {
    if (moraleQuestionItem) {
      return moraleQuestionItem.questionTopic;
    } else {
      return 0;
    }
  }

  const totalSteps = () => {
    return getSteps().length;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = (e) => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleComplete = async () => {
    // const formData = Object.keys(answer).map(item => answer[item])
    const formDataAnswer = [];
    for (const property in answer) {
      formDataAnswer.push(answer[property]);
    }
    const formDataFeedback = [];
    for (const property in feedback) {
      if (feedback[property].feedback.length > 0) {
        formDataFeedback.push(feedback[property]);
      }
    }

    console.log(formDataAnswer);
    console.log(formDataFeedback);

    const resAnswer = await dispatch(addMoraleAnswer(formDataAnswer));
    if (formDataFeedback.length > 0) {
      await dispatch(addMoraleQuestionFeedback(formDataFeedback));
    }
    if (resAnswer) {
      navigate("/morale-question");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const numberOfQuestion = moraleQuestionItem.questionTopic.reduce(
      (acc, topic) => acc + topic.questionList.length,
      0
    );
    const answerLengthPass =
      Object.keys(answer).length === numberOfQuestion ? true : false;

    let pass = 1;
    for (const property in answer) {
      pass = answer[property].answer.length > 0 ? pass * 1 : pass * 0;
    }

    if (answerLengthPass && pass) {
      handleClickOpen();
    } else {
      setOpenSnackbar(true);
    }
  };

  const updateAnswer = (idMoraleQuestion) => (event) => {
    const selectedParticipant = moraleQuestionItem.participantList.find(
      (item) => item.idEmployees === userProfile.idEmployees
    );
    let newValue = { ...answer };
    newValue[event.target.name] = {
      idMoraleQuestion: idMoraleQuestion,
      idMoraleParticipant: selectedParticipant.idMoraleParticipant,
      answer: event.target.value.trim(),
    };
    setAnswer(newValue);
  };

  const updateFeedback = (idMoraleQuestion) => (event) => {
    const selectedParticipant = moraleQuestionItem.participantList.find(
      (item) => item.idEmployees === userProfile.idEmployees
    );
    let newValue = { ...feedback };
    newValue[event.target.name] = {
      idMoraleQuestion: idMoraleQuestion,
      idMoraleParticipant: selectedParticipant.idMoraleParticipant,
      feedback: event.target.value.trim(),
    };
    setFeedback(newValue);
  };

  //   const updateAnswer = (index, idQuestion) => (event) => {
  //     // let currentData = Object.assign({}, moraleQuestionItem);
  //     const selectedParticipant = moraleQuestionItem.participantList.findIndex(
  //         (item) => item.idEmployees === currentUser.id
  //     )
  //     // console.log(selectedParticipant)
  //     console.log(idQuestion)

  //     // let currentData = moraleQuestionItem.participantList[selectedParticipant].answerList
  //     // let newAnswer = [];
  //     // newAnswer[index]["participantList"] = event.target.value.trim();

  //     // currentData.questionTopic[activeStep].questionList = newAnswer;

  //     // console.log(currentData)

  //     //setDataQuestion(currentData);
  //   };

  // useEffect(() => {
  //   const isComplete = moraleQuestionItem?.participantList.some(
  //     (user) => user.idEmployees === userProfile.idEmployees && user.isComplete
  //   );
  //   if (isComplete) {
  //     navigate("/morale-question", { replace: true });
  //   }
  // }, [moraleQuestionItem]);

  return (
    <StyledRoot className={'page'}>
      {userProfile && moraleQuestionItem && (
        <Container>
          <Paper elevation={2} style={{ padding: "16px" }}>
            <Typography variant="h4" gutterBottom>
              {moraleQuestionItem.moraleName}
            </Typography>
            <StyledTextDescription color="textSecondary">
              คำชี้แจง :{" "}
              <span style={{ fontWeight: 500 }}>
                {moraleQuestionItem.explain}
              </span>
            </StyledTextDescription>
            <StyledTextDescription color="textSecondary">
              จุดประสงค์ :{" "}
              <span style={{ fontWeight: 500 }}>
                {moraleQuestionItem.objective}
              </span>
            </StyledTextDescription>
            <StyledTextDescription color="textSecondary">
              หมายเหตุ :{" "}
              <span style={{ fontWeight: 500 }}>
                {moraleQuestionItem.remark}
              </span>
            </StyledTextDescription>
            <div style={{ marginTop: 50 }}>
              <StyledDivider />
              <Typography color="textSecondary">
                คำตอบ
              </Typography>
              <Grid container spacing={4} marginBottom={1}>
                {answerScale?.answerScale === 6
                  ? answerQuestions6Scale.map((item) => (
                      <Grid
                        item
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                      >
                        <Typography fontSize={14}>{item.label}</Typography>
                        <div style={{ width: "40px", height: "40px" }}>
                          <FeelingIcon feeling={item.key} />
                        </div>
                      </Grid>
                    ))
                  : answerQuestions5Scale.map((item) => (
                      <Grid
                        item
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                      >
                        <Typography fontSize={14}>{item.label}</Typography>
                        <div style={{ width: "40px", height: "40px" }}>
                          <FeelingIcon feeling={item.key} />
                        </div>
                      </Grid>
                    ))}
              </Grid>
              <Typography style={{ color: "#ff5f5f", fontSize: 14 }}>
                *เลือกตอบ Very Bad หรือ Bad
                รบกวนกรอกความคิดเห็นเพิ่มเติมด้วยค่ะ
              </Typography>
            </div>
          </Paper>
          <WrapStepper>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              style={{ backgroundColor: "transparent", width: "100%" }}
            >
              {moraleQuestionItem.questionTopic.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                labelProps.optional = (
                  <Typography
                    variant="body1"
                    align="center"
                    color="textSecondary"
                  >{`(${label.questionList.length} ข้อ)`}</Typography>
                );
                return (
                  <Step key={index} {...stepProps}>
                    <StyledStepLabel {...labelProps}>
                      {label.topicName}
                    </StyledStepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </WrapStepper>
          <Paper>
            <WrapHeaderQurstion>
              <Typography variant="h5" sx={{ color: "white" }}>
                {
                  moraleQuestionItem.questionTopic[activeStep]
                    .questionTopic
                }
              </Typography>
            </WrapHeaderQurstion>
            <div style={{ padding: 16 }}>
              <List component="nav" aria-label="list-question">
                {moraleQuestionItem.questionTopic[
                  activeStep
                ].questionList.map((question, index) => {
                  return (
                    <ListItem
                      key={index}
                      style={{ display: "block", paddingBottom: 48 }}
                    >
                      <Typography
                        style={{ fontSize: 24, marginBottom: 8 }}
                        variant="h4"
                      >
                        {question.question}
                      </Typography>
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Grid container spacing={2} alignItems="center">
                          {question.questionType === "choice" ? (
                            <Grid item xs={12} sm={12}>
                              <FormControl fullWidth>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  sx={{ justifyContent: "space-evenly" }}
                                  key={`${moraleQuestionItem.questionTopic[activeStep].idQuestionTopic}${question.idMoraleQuestion}`}
                                  name={`${moraleQuestionItem.questionTopic[activeStep].idQuestionTopic}${question.idMoraleQuestion}`}
                                  value={answer.answer}
                                  onChange={updateAnswer(question.idMoraleQuestion)}
                                >
                                  {answerScale?.answerScale === 6
                                    ? answerQuestions6Scale.map((item) => (
                                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                          <FormControlLabel
                                            control={
                                              <Radio
                                                checkedIcon={
                                                  <div style={{ width: "80px", height: "80px" }}>
                                                    <FeelingIcon feeling={item.key} unSelected={true} />
                                                  </div>
                                                }
                                                icon={
                                                  <div style={{ width: "80px", height: "80px" }}>
                                                    <FeelingIcon feeling={item.key} unSelected={false} />
                                                  </div>
                                                }
                                              />
                                            }
                                            value={item.value}
                                            label={item.label}
                                            labelPlacement="bottom"
                                          />
                                        </div>
                                      ))
                                    : answerQuestions5Scale.map((item) => (
                                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                          <FormControlLabel
                                            control={
                                              <Radio
                                                checkedIcon={
                                                  <div style={{ width: "80px", height: "80px" }}>
                                                    <FeelingIcon feeling={item.key} unSelected={true} />
                                                  </div>
                                                }
                                                icon={
                                                  <div style={{ width: "80px", height: "80px" }}>
                                                    <FeelingIcon feeling={item.key} unSelected={false} />
                                                  </div>
                                                }
                                              />
                                            }
                                            value={item.value}
                                            label={item.label}
                                            labelPlacement="bottom"
                                          />
                                        </div>
                                      ))
                                    }
                                </RadioGroup>
                              </FormControl>
                              <TextField
                                style={{ width: "100%", marginTop: 16 }}
                                id="comment-question"
                                label="ความคิดเห็นเพิ่มเติม"
                                multiline
                                rows={2}
                                variant="outlined"
                                inputProps={{ maxLength: 200 }}
                                value={feedback.feedback}
                                key={`${moraleQuestionItem.questionTopic[activeStep].idQuestionTopic}${question.idMoraleQuestion}`}
                                name={`${moraleQuestionItem.questionTopic[activeStep].idQuestionTopic}${question.idMoraleQuestion}`}
                                onChange={updateFeedback(
                                  question.idMoraleQuestion
                                )}
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={12}>
                              <TextField
                                fullWidth
                                multiline
                                id="comment-question"
                                label="คำตอบ"
                                rows={2}
                                variant="outlined"
                                inputProps={{ maxLength: 200 }}
                                value={answer.answer}
                                name={`${moraleQuestionItem.questionTopic[activeStep].idQuestionTopic}${question.idMoraleQuestion}`}
                                onChange={updateAnswer(
                                  question.idMoraleQuestion
                                )}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </Paper>
          <div
            style={{
              marginTop: 20,
              marginBottom: 50,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: 500,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                style={{ width: 120 }}
                variant="contained"
                color="primary"
                startIcon={<ArrowBackIosIcon />}
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                ย้อนกลับ
              </Button>
              <Button
                style={{ width: 120 }}
                variant="contained"
                color="primary"
                endIcon={
                  activeStep === steps.length - 1 ? (
                    <SaveIcon />
                  ) : (
                    <ArrowForwardIosIcon />
                  )
                }
                onClick={
                  activeStep === steps.length - 1
                    ? handleSubmit
                    : handleNext
                }
              >
                {activeStep === steps.length - 1
                  ? "ส่งคำตอบ"
                  : "ต่อไป"}
              </Button>
            </div>
          </div>
        </Container>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          โปรดตอบคำถามทุกข้อ
        </Alert>
      </Snackbar>

      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"ยืนยันส่งคำตอบ"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"สามารถตอบคำถามได้รอบเดียวเท่านั้น"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleComplete}>ยืนยัน</Button>
            <Button onClick={handleClose}>ยกเลิก</Button>
          </DialogActions>
        </Dialog>
      )}
    </StyledRoot>
  );
};

export default MoraleQuestionAnswer;
