import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Divider, Stack, Container, Box, Tabs, Tab } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import NotificationIcon from "./assets/notification.svg";
import HeaderPage from "../../../shared/header/headerPage";
import { useDispatch } from "react-redux";
import { getAllQuestionTopic } from "../../../../../actions/questionTopic";
import MoraleHistoryDaily from "./moraleDailyHistory";
import MoraleDailyActive from "./moraleDailyActive";
import MoraleDailyAll from "./moraleDailyAll";
import DialogCreateQuestion from "./dialogCreateQuestion";
import SelectAffiliateCompany from "../../../shared/general/selectAffiliateCompany";

const WrapHeader = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
})

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
})

const StyledRoot = styled('div')({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 320,
})

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Notification = () => {
  const dispatch = useDispatch()
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectCompany, setSelectCompany] = useState(null);

  const handleChangeCompany = (newValue) => {
    setSelectCompany(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getAllQuestionTopic())
  }, []);

  return (
    <StyledRoot className={'page'}>
      <WrapHeader>
        <HeaderPage textLabel={"คำถามทั้งหมด"} icon={NotificationIcon} />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
        >
          เพิ่มคำถาม
        </Button>
      </WrapHeader>

      <StyledDivider />
      <Container>
        <div style={{ margin: "16px 0px"}}>
          <SelectAffiliateCompany
            value={selectCompany}
            handleChange={handleChangeCompany}
          />
        </div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="คำถามใช้งานอยู่" {...a11yProps(0)} />
            <Tab label="คำถามทั้งหมด" {...a11yProps(1)} />
            <Tab label="ประวัติคำถาม" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <MoraleDailyActive />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <MoraleDailyAll />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <MoraleHistoryDaily selectCompany={selectCompany} />
        </CustomTabPanel>
      </Container>
      {open && (
        <DialogCreateQuestion
          open={open}
          handleClose={handleClose}
          selectCompany={selectCompany}
        />
      )}
    </StyledRoot>
  );
};

export default Notification;
